import { FC } from 'react';
import { IressText } from '@iress/ids-components';

export const TableHeading: FC = () => {
    return (
        <thead>
            <tr>
                <th />
                <th>
                    <IressText variant={'bold'} align={'right'}>
                        Nominal
                    </IressText>
                </th>
                <th>
                    <IressText variant={'bold'} align={'right'}>
                        Inflation adjusted
                    </IressText>
                </th>
            </tr>
        </thead>
    );
};
