import { LOCALE, Nullable } from '@xplan-pension-switching/common';

export const twoDecimalPlaceRegex = /^\d*(\.\d{0,2})?$/;

// the value is a string because it comes from a text input
export const formatTwoDecimalPlaces = (value: Nullable<string> | undefined): string => {
    if (value === null || value === undefined || value === '') {
        return '';
    }

    return new Intl.NumberFormat(LOCALE, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value));
};
