import { FC } from 'react';
import {
    IressForm,
    IressFormField,
    IressInline,
    IressInput,
    IressInputCurrency,
    IressPanel,
    IressRadio,
    IressRadioGroup,
    IressSelect,
    IressStack,
    IressText,
} from '@iress/ids-components';
import {
    CURRENCY_CODE,
    EscalationType,
    FrequencyType,
    LOCALE,
    OngoingContributionValues,
} from '@xplan-pension-switching/common';
import { formatTwoDecimalPlaces, twoDecimalPlaceRegex } from '../../../helpers/Numbers/TwoDecimalPlaces';
import { escalationTypeOptions, anniversaryTypeOptions } from './OngoingContributionsSelectOptions';

export const OngoingContributions: FC<{
    ongoingContributions: OngoingContributionValues;
}> = ({ ongoingContributions }) => {
    // TODO: Temporary defaulting as OngoingContributions is not currently returned from BFF. Remove when NEP-197 is complete
    const defaultOngoingContributions: OngoingContributionValues = {
        frequency: FrequencyType.Monthly,
        grossPremium: 0,
        escalationType: undefined,
        escalationPercentage: undefined,
        increasingEvery: undefined,
    };
    const tempOngoingContributions = ongoingContributions ?? defaultOngoingContributions;

    const frequencyFormValue = IressForm.useWatch({
        name: 'ongoingContributions.frequency',
        defaultValue: tempOngoingContributions.frequency,
    });

    const grossPremiumFormValue = IressForm.useWatch({
        name: 'ongoingContributions.grossPremium',
        defaultValue: tempOngoingContributions.grossPremium,
    });

    const escalationTypeFormValue = IressForm.useWatch({
        name: 'ongoingContributions.escalationType',
        defaultValue: tempOngoingContributions.escalationType,
    });

    const showEscalationType = (): boolean => {
        return Number(grossPremiumFormValue) > 0;
    };

    const showEscalationPercentage = (): boolean => {
        return escalationTypeFormValue === EscalationType.Other;
    };

    const showIncreasingEvery = (): boolean => {
        return !(
            Number(grossPremiumFormValue) === 0 ||
            (frequencyFormValue === FrequencyType.Monthly &&
                Number(grossPremiumFormValue) > 0 &&
                (escalationTypeFormValue === EscalationType.ZeroPercent || !escalationTypeFormValue))
        );
    };

    return (
        <>
            <IressText element={'h2'}>Ongoing contributions</IressText>
            <IressPanel>
                <IressStack gutter={'md'}>
                    <IressFormField
                        label={'Frequency'}
                        name={'ongoingContributions.frequency'}
                        defaultValue={tempOngoingContributions.frequency}
                        rules={{ required: true }}
                        render={controlledProps => (
                            <IressRadioGroup layout={'inline'} {...controlledProps}>
                                <IressRadio value={FrequencyType.Annually}>Annually</IressRadio>
                                <IressRadio value={FrequencyType.Monthly}>Monthly</IressRadio>
                            </IressRadioGroup>
                        )}
                    />
                    <IressInline gutter={'md'} verticalAlign={'bottom'}>
                        <IressFormField
                            label={'Gross premium'}
                            name={'ongoingContributions.grossPremium'}
                            defaultValue={tempOngoingContributions.grossPremium}
                            rules={{
                                required: 'Gross premium is required',
                                min: 0,
                                pattern: twoDecimalPlaceRegex,
                            }}
                            render={controlledProps => (
                                <IressInputCurrency
                                    type={'number'}
                                    currencyCode={CURRENCY_CODE}
                                    locale={LOCALE}
                                    width={'12'}
                                    {...controlledProps}
                                />
                            )}
                        />
                        {showEscalationType() && (
                            <IressFormField
                                label={'Escalation'}
                                name={'ongoingContributions.escalationType'}
                                defaultValue={tempOngoingContributions.escalationType}
                                rules={{
                                    required: 'Escalation is required',
                                }}
                                render={controlledProps => (
                                    <IressSelect placeholder={'Select an option'} width={'12'} {...controlledProps}>
                                        {escalationTypeOptions.map(option => (
                                            <option key={String(option.value)} value={String(option.value)}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </IressSelect>
                                )}
                            />
                        )}
                        {showEscalationPercentage() && (
                            <IressFormField
                                label={'Percentage'}
                                name={'ongoingContributions.escalationPercentage'}
                                defaultValue={tempOngoingContributions.escalationPercentage ?? 0}
                                rules={{
                                    required: 'Percentage is required',
                                    min: 0.01,
                                    max: 20,
                                    pattern: twoDecimalPlaceRegex,
                                }}
                                render={controlledProps => (
                                    <IressInput
                                        type={'number'}
                                        formatter={value => formatTwoDecimalPlaces(value)}
                                        width={'12'}
                                        append={<IressText>%</IressText>}
                                        {...controlledProps}
                                    />
                                )}
                            />
                        )}
                        {showIncreasingEvery() && (
                            <IressFormField
                                label={'Increasing every / Policy anniversary'}
                                name={'ongoingContributions.increasingEvery'}
                                defaultValue={tempOngoingContributions.increasingEvery}
                                rules={{
                                    required: 'Increasing every / Policy anniversary is required',
                                }}
                                render={controlledProps => (
                                    <IressSelect placeholder={'Select a month'} width={'12'} {...controlledProps}>
                                        {anniversaryTypeOptions.map(option => (
                                            <option key={String(option.value)} value={String(option.value)}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </IressSelect>
                                )}
                            />
                        )}
                    </IressInline>
                </IressStack>
            </IressPanel>
        </>
    );
};
