import { LabelValue } from '@iress/ids-components';
import { EscalationType } from '@xplan-pension-switching/common';

export const escalationTypeOptions: LabelValue[] = [
    {
        label: '0%',
        value: EscalationType.ZeroPercent,
    },
    {
        label: '3%',
        value: EscalationType.ThreePercent,
    },
    {
        label: '5%',
        value: EscalationType.FivePercent,
    },
    {
        label: '8%',
        value: EscalationType.EightPercent,
    },
    {
        label: '10%',
        value: EscalationType.TenPercent,
    },
    {
        label: 'AEI',
        value: EscalationType.AEI,
    },
    {
        label: 'RPI',
        value: EscalationType.RPI,
    },
    {
        label: 'Fixed percentage',
        value: EscalationType.Other,
    },
];

export const anniversaryTypeOptions: LabelValue[] = [
    {
        label: 'January',
        value: 'January',
    },
    {
        label: 'February',
        value: 'February',
    },
    {
        label: 'March',
        value: 'March',
    },
    {
        label: 'April',
        value: 'April',
    },
    {
        label: 'May',
        value: 'May',
    },
    {
        label: 'June',
        value: 'June',
    },
    {
        label: 'July',
        value: 'July',
    },
    {
        label: 'August',
        value: 'August',
    },
    {
        label: 'September',
        value: 'September',
    },
    {
        label: 'October',
        value: 'October',
    },
    {
        label: 'November',
        value: 'November',
    },
    {
        label: 'December',
        value: 'December',
    },
];
