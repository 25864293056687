import { IressTable, IressText } from '@iress/ids-components';
import { FC } from 'react';
import { calculateInflationAdjustedRateFromNominalFormated } from '../../../helpers/InflationAdjustment/InflationAdjustment';
import { ReferenceData } from '@xplan-pension-switching/common';
import { TableHeading } from './TableHeading';

export const IndustryStandardTable: FC<{ referenceData: ReferenceData }> = ({ referenceData }) => {
    const {
        inflationRatePercentage,
        industryStandardGrowthRatePercentageLow,
        industryStandardGrowthRatePercentageMid,
        industryStandardGrowthRatePercentageHigh,
    } = referenceData;

    return (
        <IressTable
            caption={'Projected Growth Rate - Industry Standard table'}
            hiddenCaption
            hover
            data-testid={'industryStandardTable'}
        >
            <TableHeading />
            <tbody>
                <tr>
                    <td>
                        <IressText align={'left'}>Low</IressText>
                    </td>
                    <td>
                        <IressText align={'right'}>{industryStandardGrowthRatePercentageLow}</IressText>
                    </td>
                    <td>
                        <IressText align={'right'}>
                            {calculateInflationAdjustedRateFromNominalFormated(
                                industryStandardGrowthRatePercentageLow,
                                inflationRatePercentage
                            )}
                        </IressText>
                    </td>
                </tr>
                <tr>
                    <td>
                        <IressText align={'left'}>Mid</IressText>
                    </td>
                    <td>
                        <IressText align={'right'}>{industryStandardGrowthRatePercentageMid}</IressText>
                    </td>
                    <td>
                        <IressText align={'right'}>
                            {calculateInflationAdjustedRateFromNominalFormated(
                                industryStandardGrowthRatePercentageMid,
                                inflationRatePercentage
                            )}
                        </IressText>
                    </td>
                </tr>
                <tr>
                    <td>
                        <IressText align={'left'}>High</IressText>
                    </td>
                    <td>
                        <IressText align={'right'}>{industryStandardGrowthRatePercentageHigh}</IressText>
                    </td>
                    <td>
                        <IressText align={'right'}>
                            {calculateInflationAdjustedRateFromNominalFormated(
                                industryStandardGrowthRatePercentageHigh,
                                inflationRatePercentage
                            )}
                        </IressText>
                    </td>
                </tr>
            </tbody>
        </IressTable>
    );
};
