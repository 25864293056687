import { FC, memo, RefObject, useContext, useState } from 'react';
import { CurrentPlanProjectionsTable } from './CurrentPlanProjectionsTable';
import { FormRef, IressAlert, IressForm, IressPanel, IressText } from '@iress/ids-components';
import { FormHandlingContext } from '../../context/FormHandlingContext';
import { useCurrentPlanProjectionsPageQuery } from '../../services/CurrentPlanProjectionsService/CurrentPlanProjectionsService';
import { useNavigate } from 'react-router-dom';
import { ScenarioProps } from '@xplan-pension-switching/common';

export type CurrentPlanProjectionsFields = NonNullable<unknown>;
export const CurrentPlanProjectionsForm = IressForm<CurrentPlanProjectionsFields>;

const CurrentPlanProjectionsFormContent: FC<ScenarioProps> = props => {
    const navigate = useNavigate();
    const { entityId, scenarioId } = props;
    const { formReference } = useContext(FormHandlingContext);
    const { data } = useCurrentPlanProjectionsPageQuery(entityId, scenarioId);
    const currentPlanProjections = data.currentPlanProjectionsPage.projections;
    const [showAlert, setShowAlert] = useState(false);

    const onSubmit = async () => {
        const allReviewed = currentPlanProjections.every(p => p.isCompleted);
        setShowAlert(!allReviewed);

        if (!allReviewed) return;

        navigate({
            pathname: `/new-investments`,
            search: `?entityid=${entityId}&scenarioId=${scenarioId}`,
        });
    };

    return (
        <>
            {showAlert ? (
                <IressAlert
                    data-testid={'key-details-error-summary'}
                    status={'danger'}
                    heading={'All plans must be reviewed'}
                />
            ) : null}
            <IressText element="h2">Review and adjust selected plans</IressText>
            <IressPanel padding={'md'} background={'default'}>
                <CurrentPlanProjectionsForm
                    onSubmit={onSubmit}
                    ref={formReference as RefObject<FormRef<CurrentPlanProjectionsFields>>}
                    mode={'onBlur'}
                    data-testid={'current-plan-projections-form'}
                >
                    <CurrentPlanProjectionsTable
                        entityId={entityId}
                        scenarioId={scenarioId}
                        currentPlanProjections={currentPlanProjections}
                    />
                </CurrentPlanProjectionsForm>
            </IressPanel>
        </>
    );
};

export const MemoizedCurrentPlanProjectionsFormContent = memo(CurrentPlanProjectionsFormContent);
