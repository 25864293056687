import { createSlice } from '@reduxjs/toolkit';
import { NavigationState, Page } from '@xplan-pension-switching/common';

const initialState: NavigationState = {
    pages: [
        {
            key: 'current-plan-projections',
        },
        {
            key: 'new-investments',
        },
        {
            key: 'advisor-charges',
        },
        {
            key: 'new-pensions',
        },
        {
            key: 'generate-report',
        },
    ],
    currentPage: null,
    previousPage: null,
    nextPage: null,
};

const setNavigationState = (state: NavigationState, action: Page): void => {
    const currentIndex = state.pages.findIndex(page => page.key === action.key);
    if (currentIndex < 0) return;
    state.currentPage = action;
    state.previousPage = state.pages[currentIndex - 1] ?? null;
    state.nextPage = state.pages[currentIndex + 1] ?? null;
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            setNavigationState(state, action.payload);
        },
    },
});

export const { setCurrentPage } = navigationSlice.actions;
export const navigationReducer = navigationSlice.reducer;
export const navigationInitialState = initialState;
