import { FC } from 'react';
import {
    IressInput,
    IressInputCurrency,
    IressPanel,
    IressStack,
    IressInline,
    IressText,
    IressFormField,
} from '@iress/ids-components';
import { CURRENCY_CODE, CurrentPlanValues, LOCALE } from '@xplan-pension-switching/common';
import { twoDecimalPlaceRegex } from '../../../helpers/Numbers/TwoDecimalPlaces';

export const CurrentValues: FC<{ currentValues: CurrentPlanValues }> = ({ currentValues }) => {
    const asAtMinDate = new Date('1900-01-01').toDateString();
    const asAtMaxDate = new Date().toDateString();

    return (
        <>
            <IressText element={'h2'}>Current values</IressText>
            <IressPanel padding={'md'} background={'default'}>
                <IressStack gutter={'md'}>
                    <IressInline gutter={'xl'} horizontalAlign={'left'} verticalAlign={'bottom'}>
                        <IressFormField
                            label="Current value"
                            name={'currentValues.currentValueAmount'}
                            defaultValue={currentValues.currentValueAmount}
                            rules={{
                                required: 'Current value is required',
                                min: 0.01,
                                pattern: twoDecimalPlaceRegex,
                            }}
                            render={controlledProps => (
                                <IressInputCurrency
                                    data-testid={'txtCurrentValue'}
                                    type={'number'}
                                    currencyCode={CURRENCY_CODE}
                                    locale={LOCALE}
                                    width={'12'}
                                    {...controlledProps}
                                />
                            )}
                        ></IressFormField>
                        <IressFormField
                            label="Transfer value"
                            name={'currentValues.transferValueAmount'}
                            defaultValue={currentValues.transferValueAmount}
                            rules={{
                                required: 'Transfer value is required',
                                min: 0.01,
                                pattern: twoDecimalPlaceRegex,
                            }}
                            render={controlledProps => (
                                <IressInputCurrency
                                    data-testid={'txtTransferValue'}
                                    type={'number'}
                                    currencyCode={CURRENCY_CODE}
                                    locale={LOCALE}
                                    width={'12'}
                                    {...controlledProps}
                                />
                            )}
                        ></IressFormField>
                        <IressFormField
                            label="As at date"
                            name={'currentValues.asAtDate'}
                            defaultValue={currentValues.asAtDate}
                            rules={{
                                required: 'As at date is required',
                                min: { value: asAtMinDate, message: `Date must be on or after ${asAtMinDate}` },
                                max: { value: asAtMaxDate, message: `Date must be on or before ${asAtMaxDate}` },
                            }}
                            render={controlledProps => (
                                <IressInput
                                    data-testid={'dtAsAtDate'}
                                    type={'date'}
                                    width={'12'}
                                    {...controlledProps}
                                />
                            )}
                        ></IressFormField>
                    </IressInline>
                </IressStack>
            </IressPanel>
        </>
    );
};
