import { FC, memo, RefObject, useContext } from 'react';
import { CurrentValues } from './CurrentValues/CurrentValues';
import { FormRef, IressForm, IressStack, IressText } from '@iress/ids-components';
import { CurrentPlanProjectionDetails, CurrentPlanProjectionDetailsProps } from '@xplan-pension-switching/common';
import {
    useCancelCurrentPlanProjectionDetailsQuery,
    useCurrentPlanProjectionDetailsMutation,
    useCurrentPlanProjectionDetailsPageQuery,
} from '../../services/CurrentPlanProjectionDetailsService/CurrentPlanProjectionDetailsService';
import { useNavigate } from 'react-router-dom';
import { FormHandlingContext } from '../../context/FormHandlingContext';
import { useReferenceDataQuery } from '../../services/ReferenceDataService/ReferenceDataService';
import { ProjectedGrowthRates } from './ProjectedGrowthRates/ProjectedGrowthRates';
import { OngoingContributions } from './OngoingContributions/OngoingContributions';
import { ProjectedValues } from './ProjectedValues/ProjectedValues';

export const CurrentPlanProjectionDetailsForm = IressForm<CurrentPlanProjectionDetails>;

const CurrentPlanProjectionDetailsFormContent: FC<CurrentPlanProjectionDetailsProps> = props => {
    const { entityId, scenarioId, projectionId } = props;
    const navigate = useNavigate();
    const mutation = useCurrentPlanProjectionDetailsMutation();
    const cancelQuery = useCancelCurrentPlanProjectionDetailsQuery(entityId, scenarioId, projectionId);
    const { formReference } = useContext(FormHandlingContext);
    const { data } = useCurrentPlanProjectionDetailsPageQuery(entityId, scenarioId, projectionId);
    const { currentValues, ongoingContributions, projectedGrowthRates, projectedValues } =
        data.currentPlanProjectionDetailsPage.details;
    const refDataQueryResponse = useReferenceDataQuery();
    const referenceData = refDataQueryResponse.data.referenceData;

    const onSubmit = async (values: CurrentPlanProjectionDetails) => {
        const toUpdate = { details: values };

        await mutation.mutateAsync(
            { entityId, scenarioId, projectionId, ...toUpdate },
            {
                onSuccess: () => {
                    cancelQuery();
                    navigate({
                        pathname: `/current-plan-projections`,
                        search: `?entityid=${entityId}&scenarioId=${scenarioId}`,
                    });
                },
            }
        );
    };

    return (
        <CurrentPlanProjectionDetailsForm
            onSubmit={onSubmit}
            ref={formReference as RefObject<FormRef<CurrentPlanProjectionDetails>>}
            mode={'onBlur'}
            data-testid={'current-plan-projection-details-form'}
            shouldUnregister
        >
            <IressStack gutter={'md'}>
                <IressText element={'h2'} variant={'bold'}>
                    {data.currentPlanProjectionDetailsPage.name}
                </IressText>
                <CurrentValues currentValues={currentValues} />
                <OngoingContributions ongoingContributions={ongoingContributions} />
                <ProjectedValues
                    projectedValues={projectedValues}
                    clientAge={data?.currentPlanProjectionDetailsPage?.owner?.age ?? 0}
                    referenceData={referenceData}
                />
                <ProjectedGrowthRates projectedGrowthRates={projectedGrowthRates} referenceData={referenceData} />
            </IressStack>
        </CurrentPlanProjectionDetailsForm>
    );
};

export const MemoizedCurrentPlanProjectionDetailsFormContent = memo(CurrentPlanProjectionDetailsFormContent);
