import { FC, useEffect, useState } from 'react';
import {
    GrowthRateBasisType,
    Nullable,
    ProjectedGrowthRateValues,
    ReferenceData,
} from '@xplan-pension-switching/common';
import {
    IressDivider,
    IressForm,
    IressFormField,
    IressPanel,
    IressSelect,
    IressText,
    LabelValue,
} from '@iress/ids-components';
import { IndustryStandardTable } from './IndustryStandardTable';
import { FundSpecificNominalTable } from './FundSpecificNominalTable';
import { FundSpecificInflationTable } from './FundSpecificInflationTable';

export const ProjectedGrowthRates: FC<{
    projectedGrowthRates: Nullable<ProjectedGrowthRateValues>;
    referenceData: ReferenceData;
}> = ({ projectedGrowthRates, referenceData }) => {
    const growthValueTypeOptions: LabelValue[] = [
        {
            label: 'Industry standard',
            value: GrowthRateBasisType.IndustryStandard,
        },
        {
            label: 'Fund specific: nominal',
            value: GrowthRateBasisType.FundSpecificNominal,
        },
        {
            label: 'Fund specific: inflation',
            value: GrowthRateBasisType.FundSpecificInflation,
        },
    ];

    const growthRateBasisDefaultValue = projectedGrowthRates
        ? projectedGrowthRates.growthRateBasis
        : GrowthRateBasisType.IndustryStandard;

    const growthRateBasisFormValue = IressForm.useWatch({
        name: 'projectedGrowthRates.growthRateBasis',
        defaultValue: growthRateBasisDefaultValue,
    });

    const [growthRateLow, setGrowthRateLow] = useState(projectedGrowthRates?.growthRateLow ?? 0);
    const [growthRateMid, setGrowthRateMid] = useState(projectedGrowthRates?.growthRateMid ?? 0);
    const [growthRateHigh, setGrowthRateHigh] = useState(projectedGrowthRates?.growthRateHigh ?? 0);

    const form = IressForm.useForm();

    useEffect(() => {
        form.setValue('projectedGrowthRates.growthRateLow', null);
        form.setValue('projectedGrowthRates.growthRateMid', null);
        form.setValue('projectedGrowthRates.growthRateHigh', null);
        setGrowthRateLow(0);
        setGrowthRateMid(0);
        setGrowthRateHigh(0);
    }, [growthRateBasisFormValue, form]);

    return (
        <>
            <IressText element={'h2'}>Projected growth rates</IressText>
            <IressPanel>
                <IressFormField
                    label={'Growth rate type'}
                    name={'projectedGrowthRates.growthRateBasis'}
                    defaultValue={growthRateBasisDefaultValue}
                    rules={{ required: true }}
                    render={controlledProps => (
                        <IressSelect data-testid={'growthValueType'} width={'25perc'} {...controlledProps}>
                            {growthValueTypeOptions.map(option => (
                                <option key={String(option.value)} value={String(option.value)}>
                                    {option.label}
                                </option>
                            ))}
                        </IressSelect>
                    )}
                />
                <IressDivider />
                {Number(growthRateBasisFormValue) === GrowthRateBasisType.IndustryStandard ? (
                    <IndustryStandardTable referenceData={referenceData} />
                ) : null}
                {Number(growthRateBasisFormValue) === GrowthRateBasisType.FundSpecificNominal ? (
                    <FundSpecificNominalTable
                        growthRateLow={growthRateLow}
                        growthRateMid={growthRateMid}
                        growthRateHigh={growthRateHigh}
                        referenceData={referenceData}
                    />
                ) : null}
                {Number(growthRateBasisFormValue) === GrowthRateBasisType.FundSpecificInflation ? (
                    <FundSpecificInflationTable />
                ) : null}
            </IressPanel>
        </>
    );
};
