import { FC } from 'react';
import { LoadingAndErrorHandler, LoadingPlaceholder, PageErrorWithReload, Screen } from '../../components';

export const NewInvestments: FC = () => {
    return (
        <Screen title={'New Investments'}>
            <LoadingAndErrorHandler
                LoadingComponentFallback={<LoadingPlaceholder height={'300'} />}
                ErrorComponentFallback={<PageErrorWithReload />}
            ></LoadingAndErrorHandler>
        </Screen>
    );
};
