import { ReferenceData } from '@xplan-pension-switching/common';
import {
    industryStandardGrowthRatePercentageHigh,
    industryStandardGrowthRatePercentageLow,
    industryStandardGrowthRatePercentageMid,
    inflationRatePercentage,
    maximumRetirementAge,
    maximumTermForCalculations,
    minimumRetirementAge,
} from './referenceData';
import { useSuspenseQuery } from '@tanstack/react-query';

const CACHE_KEY = 'reference-data';

export const getReferenceDataAsync = async (): Promise<{ referenceData: ReferenceData }> => {
    const referenceData: ReferenceData = {
        inflationRatePercentage,
        industryStandardGrowthRatePercentageLow,
        industryStandardGrowthRatePercentageMid,
        industryStandardGrowthRatePercentageHigh,
        minimumRetirementAge,
        maximumRetirementAge,
        maximumTermForCalculations,
    };

    return new Promise(resolve => resolve({ referenceData }));
};

export const useReferenceDataQuery = () =>
    useSuspenseQuery({ queryKey: [CACHE_KEY], queryFn: () => getReferenceDataAsync() });
