import { FC } from 'react';
import {
    CURRENCY_CODE,
    CurrentPlanProjectionDetails,
    LOCALE,
    MaturityValuesProjectedEnumType,
    Nullable,
    PensionStatementDataEntryMethod,
    ProjectedMaturityBasisType,
    ProjectedValuesValues,
    ReferenceData,
} from '@xplan-pension-switching/common';
import {
    IressForm,
    IressFormField,
    IressInline,
    IressInput,
    IressInputCurrency,
    IressPanel,
    IressRadio,
    IressRadioGroup,
    IressSelect,
    IressStack,
    IressText,
    LabelValue,
} from '@iress/ids-components';
import { formatTwoDecimalPlaces, twoDecimalPlaceRegex } from '../../../helpers/Numbers/TwoDecimalPlaces';

export type ProjectedValuesProps = {
    projectedValues: ProjectedValuesValues;
    clientAge: number;
    referenceData: ReferenceData;
};

export const ProjectedValues: FC<ProjectedValuesProps> = props => {
    const { projectedValues, clientAge, referenceData } = props;
    const allChargesZeroErrorMessage = 'At least one charge must be greater than 0';

    const { clearErrors, setError, getFieldState } = IressForm.useFormContext<CurrentPlanProjectionDetails>();

    const maturityValuesProjectedValue: MaturityValuesProjectedEnumType = IressForm.useWatch({
        name: 'projectedValues.maturityValuesProjected',
        defaultValue: projectedValues?.maturityValuesProjected ?? MaturityValuesProjectedEnumType.Age,
    });

    const pensionStatementDataEntryMethod: PensionStatementDataEntryMethod = IressForm.useWatch({
        name: 'projectedValues.pensionStatementDataEntryMethod',
        defaultValue:
            projectedValues?.pensionStatementDataEntryMethod ?? PensionStatementDataEntryMethod.ProjectedMaturityBasis,
    });

    const calculationMethod: Nullable<ProjectedMaturityBasisType> = IressForm.useWatch({
        name: 'projectedValues.calculationMethod',
        defaultValue: projectedValues?.calculationMethod,
    });

    const fundChargeValue: Nullable<number> = IressForm.useWatch({
        name: 'projectedValues.fundCharge',
        defaultValue: projectedValues?.fundCharge,
    });

    const planChargeValue: Nullable<number> = IressForm.useWatch({
        name: 'projectedValues.planCharge',
        defaultValue: projectedValues?.planCharge,
    });

    const policyFeeValue: Nullable<number> = IressForm.useWatch({
        name: 'projectedValues.policyFee',
        defaultValue: projectedValues?.policyFee,
    });

    const advisorChargeValue: Nullable<number> = IressForm.useWatch({
        name: 'projectedValues.advisorCharge',
        defaultValue: projectedValues?.advisorCharge,
    });

    const projectMaturityBasisTypeOptions: LabelValue[] = [
        {
            label: 'Monetary projections supplied',
            value: ProjectedMaturityBasisType.MonetaryProjectionsSupplied,
        },
        {
            label: 'Inflation adjusted rates supplied',
            value: ProjectedMaturityBasisType.InflationAdjustedRatesSupplied,
        },
    ];

    const calculationMethodOptions: LabelValue[] = [
        {
            label: 'Using product charges',
            value: ProjectedMaturityBasisType.UsingProductCharges,
        },
        {
            label: 'Calculated using A.M.C',
            value: ProjectedMaturityBasisType.CalculatedUsingAMC,
        },
    ];

    const fields = [
        'projectedValues.fundCharge',
        'projectedValues.planCharge',
        'projectedValues.policyFee',
        'projectedValues.advisorCharge',
    ];

    const atLeastOneProductChargeGreaterThanZero = (currentValidation: string) => {
        const isValid =
            (fundChargeValue ?? 0) > 0 ||
            (planChargeValue ?? 0) > 0 ||
            (policyFeeValue ?? 0) > 0 ||
            (advisorChargeValue ?? 0) > 0;

        if (isValid) {
            // want to clear the *other* fields, leave the current validator to clear the current field
            const toClear = fields.filter(field => field !== currentValidation);
            toClear.forEach(field => {
                // @ts-expect-error - the field array contains the correct field names but TS doesn't know that
                if (field && getFieldState(field)?.error?.message === allChargesZeroErrorMessage) {
                    // @ts-expect-error - the field array contains the correct field names but TS doesn't know that
                    clearErrors(field);
                }
            });
        } else {
            // @ts-expect-error - the field array contains the correct field names but TS doesn't know that
            fields.forEach(field => setError(field, { message: allChargesZeroErrorMessage }));
        }

        return isValid;
    };

    const projectToTermFields = (
        <IressFormField
            label={'Term'}
            name={'projectedValues.term'}
            defaultValue={projectedValues?.term}
            rules={{
                required: 'Term is required',
                min: {
                    value: 1,
                    message: `Enter a number between 1 and ${referenceData.maximumTermForCalculations}`,
                },
                max: {
                    value: referenceData.maximumTermForCalculations,
                    message: `Enter a number between 1 and ${referenceData.maximumTermForCalculations}`,
                },
            }}
            render={controlledProps => (
                <IressInput {...controlledProps} type={'number'} width={'12'} data-testid={'txtTerm'} />
            )}
        />
    );

    const assumedChargesAndGrowthRatesFields = (
        <>
            <IressFormField
                label={'Calculation method'}
                name={'projectedValues.calculationMethod'}
                rules={{ required: 'Calculation method is required' }}
                defaultValue={projectedValues?.calculationMethod}
                render={controlledProps => (
                    <IressSelect
                        {...controlledProps}
                        data-testid={'ddlCalculationMethod'}
                        width={'12'}
                        placeholder={'Select an option'}
                    >
                        {calculationMethodOptions.map(option => (
                            <option key={String(option.value)} value={String(option.value)}>
                                {option.label}
                            </option>
                        ))}
                    </IressSelect>
                )}
            />
            {calculationMethod === ProjectedMaturityBasisType.UsingProductCharges && (
                <IressInline gutter={'xl'} horizontalAlign={'left'} verticalAlign={'bottom'}>
                    <IressFormField
                        label={'Fund charge'}
                        name={'projectedValues.fundCharge'}
                        defaultValue={projectedValues?.fundCharge ?? 0}
                        rules={{
                            pattern: twoDecimalPlaceRegex,
                            required: 'Fund charge is required',
                            min: { message: 'Please enter a value equal to or greater than 0', value: 0 },
                            validate: () =>
                                atLeastOneProductChargeGreaterThanZero('projectedValues.fundCharge') ||
                                allChargesZeroErrorMessage,
                        }}
                        render={controlledProps => (
                            <IressInput
                                {...controlledProps}
                                type={'number'}
                                width={'12'}
                                data-testid={'txtFundCharge'}
                                append={<span>%</span>}
                                placeholder={'0.00'}
                                formatter={value => formatTwoDecimalPlaces(value)}
                            />
                        )}
                    />
                    <IressFormField
                        label={'Plan charge'}
                        name={'projectedValues.planCharge'}
                        defaultValue={projectedValues?.planCharge ?? 0}
                        rules={{
                            pattern: twoDecimalPlaceRegex,
                            required: 'Plan charge is required',
                            min: { message: 'Please enter a value equal to or greater than 0', value: 0 },
                            validate: () =>
                                atLeastOneProductChargeGreaterThanZero('projectedValues.planCharge') ||
                                allChargesZeroErrorMessage,
                        }}
                        render={controlledProps => (
                            <IressInput
                                {...controlledProps}
                                type={'number'}
                                width={'12'}
                                data-testid={'txtPlanCharge'}
                                append={<span>%</span>}
                                placeholder={'0.00'}
                                formatter={value => formatTwoDecimalPlaces(value)}
                            />
                        )}
                    />
                    <IressFormField
                        label={'Policy fee'}
                        name={'projectedValues.policyFee'}
                        defaultValue={projectedValues?.policyFee ?? 0}
                        rules={{
                            pattern: twoDecimalPlaceRegex,
                            required: 'Policy fee is required',
                            min: { message: 'Please enter a value equal to or greater than 0', value: 0 },
                            validate: () =>
                                atLeastOneProductChargeGreaterThanZero('projectedValues.policyFee') ||
                                allChargesZeroErrorMessage,
                        }}
                        render={controlledProps => (
                            <IressInputCurrency
                                {...controlledProps}
                                currencyCode={CURRENCY_CODE}
                                locale={LOCALE}
                                placeholder={'0.00'}
                                width={'12'}
                                data-testid={'txtPolicyFee'}
                            />
                        )}
                    />
                    <IressFormField
                        label={'Advisor charge'}
                        name={'projectedValues.advisorCharge'}
                        defaultValue={projectedValues?.advisorCharge ?? 0}
                        rules={{
                            pattern: twoDecimalPlaceRegex,
                            min: { message: 'Please enter a value equal to or greater than 0', value: 0 },
                            required: 'Advisor charge is required',
                            validate: () =>
                                atLeastOneProductChargeGreaterThanZero('projectedValues.advisorCharge') ||
                                allChargesZeroErrorMessage,
                        }}
                        render={controlledProps => (
                            <IressInput
                                {...controlledProps}
                                type={'number'}
                                width={'12'}
                                data-testid={'txtAdvisorCharge'}
                                append={<span>%</span>}
                                placeholder={'0.00'}
                                formatter={value => formatTwoDecimalPlaces(value)}
                            />
                        )}
                    />
                </IressInline>
            )}
            {calculationMethod === ProjectedMaturityBasisType.CalculatedUsingAMC && (
                <IressInline gutter={'xl'} horizontalAlign={'left'} verticalAlign={'bottom'}>
                    <IressFormField
                        label={'A.M.C'}
                        name={'projectedValues.amc'}
                        defaultValue={projectedValues?.amc}
                        rules={{
                            required: 'A.M.C is required',
                            pattern: twoDecimalPlaceRegex,
                            min: { message: 'Please enter a value equal to or greater than 0', value: 0 },
                        }}
                        render={controlledProps => (
                            <IressInput
                                {...controlledProps}
                                type={'number'}
                                width={'12'}
                                data-testid={'txtAMC'}
                                append={<span>%</span>}
                                placeholder={'0.00'}
                                formatter={value => formatTwoDecimalPlaces(value)}
                            />
                        )}
                    />
                </IressInline>
            )}
        </>
    );

    const projectToAgeFields = (
        <IressFormField
            label={'Retirement age'}
            name={'projectedValues.retirementAge'}
            defaultValue={projectedValues?.retirementAge}
            rules={{
                required: 'Retirement age is required',
                min: {
                    value: Math.max(referenceData.minimumRetirementAge, clientAge + 1),
                    message:
                        clientAge < referenceData.minimumRetirementAge
                            ? `Enter a valid retirement age (minimum age is ${referenceData.minimumRetirementAge})`
                            : `Enter a valid retirement age (minimum age is ${clientAge + 1})`,
                },
                max: referenceData.maximumRetirementAge,
            }}
            render={controlledProps => (
                <IressInput {...controlledProps} type={'number'} width={'12'} data-testid={'txtRetirementAge'} />
            )}
        />
    );

    const projectedMaturityBasisFields = (
        <IressInline gutter={'xl'} horizontalAlign={'left'} verticalAlign={'bottom'}>
            <IressFormField
                label={'Projection type'}
                name={'projectedValues.projectionType'}
                rules={{ required: 'Projection type is required' }}
                defaultValue={projectedValues?.projectionType}
                render={controlledProps => (
                    <IressSelect
                        {...controlledProps}
                        data-testid={'ddlProjectionType'}
                        width={'12'}
                        placeholder={'Select an option'}
                    >
                        {projectMaturityBasisTypeOptions.map(option => (
                            <option key={String(option.value)} value={String(option.value)}>
                                {option.label}
                            </option>
                        ))}
                    </IressSelect>
                )}
            />
            <IressFormField
                label={'Low rate maturity value'}
                name={'projectedValues.lowRateMaturityValue'}
                defaultValue={projectedValues?.lowRateMaturityValue}
                rules={{
                    required: 'Low rate maturity value is required',
                    min: { value: 0.01, message: 'Please enter a value greater than 0' },
                    pattern: twoDecimalPlaceRegex,
                }}
                render={controlledProps => (
                    <IressInputCurrency
                        {...controlledProps}
                        data-testid={'txtLowRateMaturityValue'}
                        currencyCode={CURRENCY_CODE}
                        locale={LOCALE}
                        placeholder={'0.00'}
                        width={'12'}
                    />
                )}
            />
            <IressFormField
                label={'Mid rate maturity value'}
                name={'projectedValues.midRateMaturityValue'}
                defaultValue={projectedValues?.midRateMaturityValue}
                rules={{
                    required: 'Mid rate maturity value is required',
                    min: { value: 0.01, message: 'Please enter a value greater than 0' },
                    pattern: twoDecimalPlaceRegex,
                }}
                render={controlledProps => (
                    <IressInputCurrency
                        {...controlledProps}
                        data-testid={'txtMidRateMaturityValue'}
                        currencyCode={CURRENCY_CODE}
                        locale={LOCALE}
                        placeholder={'0.00'}
                        width={'12'}
                    />
                )}
            />
            <IressFormField
                label={'High rate maturity value'}
                name={'projectedValues.highRateMaturityValue'}
                defaultValue={projectedValues?.highRateMaturityValue}
                rules={{
                    required: 'High rate maturity value is required',
                    min: { value: 0.01, message: 'Please enter a value greater than 0' },
                    pattern: twoDecimalPlaceRegex,
                }}
                render={controlledProps => (
                    <IressInputCurrency
                        {...controlledProps}
                        data-testid={'txtHighRateMaturityValue'}
                        type={'number'}
                        currencyCode={CURRENCY_CODE}
                        locale={LOCALE}
                        placeholder={'0.00'}
                        width={'12'}
                    />
                )}
            />
        </IressInline>
    );

    return (
        <>
            <IressText element={'h2'}>Projected values</IressText>
            <IressPanel>
                <IressStack gutter={'md'}>
                    <IressFormField
                        label={'Projection to'}
                        name={'projectedValues.maturityValuesProjected'}
                        defaultValue={projectedValues?.maturityValuesProjected ?? MaturityValuesProjectedEnumType.Age}
                        rules={{ required: true }}
                        render={controlledProps => (
                            <IressRadioGroup layout={'inline'} {...controlledProps}>
                                <IressRadio data-testid={'rdRetirementAge'} value={MaturityValuesProjectedEnumType.Age}>
                                    Retirement age
                                </IressRadio>
                                <IressRadio data-testid={'rdSpecificTerm'} value={MaturityValuesProjectedEnumType.Term}>
                                    Specific term
                                </IressRadio>
                            </IressRadioGroup>
                        )}
                    />
                    {maturityValuesProjectedValue === MaturityValuesProjectedEnumType.Term && projectToTermFields}
                    {maturityValuesProjectedValue === MaturityValuesProjectedEnumType.Age && projectToAgeFields}
                    <IressFormField
                        label={'Select a pension statement data entry method'}
                        name={'projectedValues.pensionStatementDataEntryMethod'}
                        defaultValue={
                            projectedValues?.pensionStatementDataEntryMethod ??
                            PensionStatementDataEntryMethod.ProjectedMaturityBasis
                        }
                        rules={{ required: true }}
                        render={controlledProps => (
                            <IressRadioGroup layout={'inline'} {...controlledProps}>
                                <IressRadio
                                    data-testid={'rdProjectedMaturityBasis'}
                                    value={PensionStatementDataEntryMethod.ProjectedMaturityBasis}
                                >
                                    Projected maturity basis
                                </IressRadio>
                                <IressRadio
                                    data-testid={'rdAssumedChargesAndGrowthRates'}
                                    value={PensionStatementDataEntryMethod.AssumedChargesAndGrowthRates}
                                >
                                    Assumed charges and growth rates
                                </IressRadio>
                            </IressRadioGroup>
                        )}
                    />
                    {pensionStatementDataEntryMethod === PensionStatementDataEntryMethod.ProjectedMaturityBasis &&
                        projectedMaturityBasisFields}
                    {pensionStatementDataEntryMethod === PensionStatementDataEntryMethod.AssumedChargesAndGrowthRates &&
                        assumedChargesAndGrowthRatesFields}
                </IressStack>
            </IressPanel>
        </>
    );
};
