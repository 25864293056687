import { FC } from 'react';
import { IressForm, IressFormField, IressInput, IressTable, IressText } from '@iress/ids-components';
import { ReferenceData } from '@xplan-pension-switching/common';
import { calculateInflationAdjustedRateFromNominalFormated } from '../../../helpers/InflationAdjustment/InflationAdjustment';
import { formatTwoDecimalPlaces, twoDecimalPlaceRegex } from '../../../helpers/Numbers/TwoDecimalPlaces';
import { TableHeading } from './TableHeading';

export const FundSpecificNominalTable: FC<{
    growthRateLow: number;
    growthRateMid: number;
    growthRateHigh: number;
    referenceData: ReferenceData;
}> = ({ growthRateLow, growthRateMid, growthRateHigh, referenceData }) => {
    const allRatesZeroErrorMessage = 'At least one rate must be greater than 0';

    const { inflationRatePercentage } = referenceData;

    const growthRateLowFormValue: number = IressForm.useWatch({
        name: 'projectedGrowthRates.growthRateLow',
        defaultValue: growthRateLow,
    });

    const growthRateMidFormValue: number = IressForm.useWatch({
        name: 'projectedGrowthRates.growthRateMid',
        defaultValue: growthRateMid,
    });

    const growthRateHighFormValue: number = IressForm.useWatch({
        name: 'projectedGrowthRates.growthRateHigh',
        defaultValue: growthRateHigh,
    });

    const atLeastOneGrowthRateGreaterThanZero = (): boolean => {
        return growthRateLowFormValue > 0 || growthRateMidFormValue > 0 || growthRateHighFormValue > 0;
    };

    return (
        <IressTable
            caption={'Projected Growth Rate - Fund Specific Nominal table'}
            hiddenCaption
            hover
            data-testid={'fundSpecificNominalTable'}
        >
            <TableHeading />
            <tbody>
                <tr>
                    <td align={'left'}>
                        <IressText>Low</IressText>
                    </td>
                    <td align={'right'}>
                        <IressFormField
                            label={'Growth Rate Low'}
                            hiddenLabel
                            name={'projectedGrowthRates.growthRateLow'}
                            defaultValue={growthRateLow}
                            rules={{
                                required: 'Projected Growth Rate - Low is required',
                                min: -20,
                                max: 20,
                                validate: () => atLeastOneGrowthRateGreaterThanZero() || allRatesZeroErrorMessage,
                                pattern: twoDecimalPlaceRegex,
                            }}
                            render={controlledProps => (
                                <IressInput
                                    type={'number'}
                                    formatter={value => formatTwoDecimalPlaces(value)}
                                    width={'12'}
                                    append={<IressText>%</IressText>}
                                    {...controlledProps}
                                />
                            )}
                        />
                    </td>
                    <td align={'right'}>
                        <IressText data-testid={'inflationAdjustedGrowthRateLow'}>
                            {calculateInflationAdjustedRateFromNominalFormated(
                                growthRateLowFormValue,
                                inflationRatePercentage
                            )}
                        </IressText>
                    </td>
                </tr>
                <tr>
                    <td align={'left'}>
                        <IressText>Mid</IressText>
                    </td>
                    <td align={'right'}>
                        <IressFormField
                            label={'Growth Rate Mid'}
                            hiddenLabel
                            name={'projectedGrowthRates.growthRateMid'}
                            defaultValue={growthRateMid}
                            rules={{
                                required: 'Projected Growth Rate - Mid is required',
                                min: -20,
                                max: 20,
                                validate: () => atLeastOneGrowthRateGreaterThanZero() || allRatesZeroErrorMessage,
                                pattern: twoDecimalPlaceRegex,
                            }}
                            render={controlledProps => (
                                <IressInput
                                    type={'number'}
                                    formatter={value => formatTwoDecimalPlaces(value)}
                                    width={'12'}
                                    append={<IressText>%</IressText>}
                                    {...controlledProps}
                                />
                            )}
                        />
                    </td>
                    <td align={'right'}>
                        <IressText data-testid={'inflationAdjustedGrowthRateMid'}>
                            {calculateInflationAdjustedRateFromNominalFormated(
                                growthRateMidFormValue,
                                inflationRatePercentage
                            )}
                        </IressText>
                    </td>
                </tr>
                <tr>
                    <td align={'left'}>
                        <IressText>High</IressText>
                    </td>
                    <td align={'right'}>
                        <IressFormField
                            label={'Growth Rate High'}
                            hiddenLabel
                            name={'projectedGrowthRates.growthRateHigh'}
                            defaultValue={growthRateHigh}
                            rules={{
                                required: 'Projected Growth Rate - High is required',
                                min: -20,
                                max: 20,
                                validate: () => atLeastOneGrowthRateGreaterThanZero() || allRatesZeroErrorMessage,
                                pattern: twoDecimalPlaceRegex,
                            }}
                            render={controlledProps => (
                                <IressInput
                                    type={'number'}
                                    formatter={value => formatTwoDecimalPlaces(value)}
                                    width={'12'}
                                    append={<IressText>%</IressText>}
                                    {...controlledProps}
                                />
                            )}
                        />
                    </td>
                    <td align={'right'}>
                        <IressText data-testid={'inflationAdjustedGrowthRateHigh'}>
                            {calculateInflationAdjustedRateFromNominalFormated(
                                growthRateHighFormValue,
                                inflationRatePercentage
                            )}
                        </IressText>
                    </td>
                </tr>
            </tbody>
        </IressTable>
    );
};
