import { FC, useEffect, useState } from 'react';
import { IressText } from '@iress/ids-components';
import { PlanSelection } from '../PlanSelection/PlanSelection';
import { useRetirementFundsQuery } from '../../../services/ClientsService/ClientsService';
import { ScenarioModalProps } from './ScenarioModal';
import { ScenarioModalFooter } from './ScenarioModalFooter';
import { useCreateScenarioMutation } from '../../../services/ScenariosService/ScenariosService';
import { useEntityId } from '../../../hooks/UseEntityId/UseEntityId';
import { useNavigate } from 'react-router-dom';

export const ScenarioModalContent: FC<ScenarioModalProps> = props => {
    const entityId = useEntityId();
    const navigate = useNavigate();
    const { request, setHeader, setFooter, setShow } = props;
    const [requestObject, setRequestObject] = useState(request);
    const { data } = useRetirementFundsQuery(request.ownerId);
    const mutation = useCreateScenarioMutation();
    const hasFunds = data && data.retirementFunds.length > 0;

    const onSelectionChanged = (retirementFundIds: number[]) => {
        setRequestObject({ ...requestObject, retirementFundIds });
    };

    const onSubmit = async () => {
        if (!requestObject.retirementFundIds || requestObject.retirementFundIds.length === 0) {
            return { success: false, errorMessage: 'Select at least one plan to continue' };
        }

        return await mutation
            .mutateAsync({ entityId, request: requestObject })
            .then(result => {
                navigate({
                    pathname: 'current-plan-projections',
                    search: `?entityid=${entityId}&ownerId=${request.ownerId}&scenarioId=${result.scenario?.id}`,
                });
                return { success: result && result.scenario?.id > 0, errorMessage: null };
            })
            .catch(error => {
                return { success: false, errorMessage: error.message };
            });
    };

    useEffect(() => {
        if (setHeader) {
            setHeader(hasFunds ? 'Select funds for pension switching scenario' : 'No available pension plans');
        }

        if (setFooter) {
            setFooter(<ScenarioModalFooter hasFunds={hasFunds} setShow={setShow} onSubmit={onSubmit} />);
        }
    }, [hasFunds]);

    useEffect(() => {
        if (setFooter) {
            setFooter(<ScenarioModalFooter hasFunds={hasFunds} setShow={setShow} onSubmit={onSubmit} />);
        }
    }, [requestObject]);

    return hasFunds ? (
        <PlanSelection plans={data.retirementFunds} onSelectionChanged={onSelectionChanged} />
    ) : (
        <IressText data-testid={'no-plans-available'}>
            This client has no pensions on their record. To continue, please add a pension(s).
        </IressText>
    );
};
